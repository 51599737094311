<template>
	<S2SForm title="Airtime Routing">
		<v-layout justify-end>
			<v-flex xs12 md6>
				<v-text-field label="Filter" v-model="searchVal" clearable></v-text-field>
			</v-flex>
		</v-layout>
		<v-data-table
			ref="dataTable"
			:headers="headers"
			:items="airtimeRouting"
			:loading="status.loading"
			:options.sync="pagination"
			:search="searchVal"
			class="elevation-1"
		>
			<template v-slot:item.vendor="props">
				<v-menu v-model="vendorModal[props.item.id]" close-on-content-click transition="scale-transition" offset-y>
					<template v-slot:activator="{ on }">
						<v-btn text color="accent" v-on="on">
							{{ props.item.vendor }}
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in vendors"
							:key="index"
							:class="props.item.vendorId == item.id ? 'v-list-item--active' : ''"
							@click="updateVendor(props.item, item.id)"
						>
							<v-list-item-title>{{ item.description }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<template v-slot:item.blocked="{ item }">
				<v-checkbox
					class="mt-0"
					:input-value="item.blocked"
					color="accent"
					hide-details
					@change="updateBlocked($event, item)"
					@click.once.stop
				></v-checkbox>
			</template>
			<template v-slot:item.blockedMessage="props">
				<v-edit-dialog persistent @save="updateBlockedMessage(props.item)">
					{{ props.item.blockedMessage }}
					<template v-slot:input>
						<v-text-field v-model="props.item.blockedMessage" label="Blocked Message" single-line counter></v-text-field>
					</template>
				</v-edit-dialog>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "AirtimeRouting",

	computed: {
		airtimeRouting: function() {
			return this.$store.state.airtime.airtimeRouting;
		},
		vendors: function() {
			return this.$store.state.airtime.vendors;
		},
		status: function() {
			return this.$store.state.airtime.status;
		}
	},

	data: function() {
		return {
			vendorModal: {},
			headers: [
				{
					text: "Network",
					value: "network"
				},
				{
					text: "Vendor",
					value: "vendor",
					sortable: false
				},
				{
					text: "Blocked",
					value: "blocked",
					sortable: false
				},
				{
					text: "Blocked Message",
					value: "blockedMessage"
				}
			],

			pagination: {
				page: 1,
				itemsPerPage: 10 // -1 for All
			},

			searchVal: "",
			dialog: false
		};
	},

	mounted: function() {
		this.$store.dispatch("airtime/fetchAirtimeRouting");
		this.$store.dispatch("airtime/fetchAirtimeVendors");
	},

	methods: {
		updateVendor(item, vendorId) {
			item.vendorId = vendorId;
			this.$store.dispatch("airtime/updateAirtimeRouting", item);
		},
		updateBlocked(value, item) {
			item.blocked = value;
			this.$store.dispatch("airtime/updateAirtimeRouting", item);
		},
		updateBlockedMessage(item) {
			this.$store.dispatch("airtime/updateAirtimeRouting", item);
		}
	}
});
</script>

<style scoped></style>
